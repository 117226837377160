import React, { useState } from 'react'
import { Button, Container } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import { UNMAP_AGREEMENT_MAPPING } from '../../../../../../../../../Utils/GlobalConstants';
import { api } from '../../../../../../../../../Utils/API';
import Toast from '../../../../../../../../../GlobalComponents/Toast';

const Unmap = ({ unMapActionPopup, setUnMapActionPopup, completeRowData, setActionCompletedRefreshTable, Logout }) => {

    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);            // loading state for processing button

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //unmap api 
    async function UnmapAction(agreementMappingId) {
        setConfirmLoadingFlag(true);
        await api.post(`${UNMAP_AGREEMENT_MAPPING}${agreementMappingId}`, null, config)

            .then(resp => {
                setConfirmLoadingFlag(false);
                if (resp.status == 200) {
                    setUnMapActionPopup(false);
                    setToastMessage("Your subscription has been unmapped successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 2000);
                    setActionCompletedRefreshTable(true);
                }
            })
            .catch(error => {
                setConfirmLoadingFlag(false);
                if (error.response.status == 401) {
                    Logout();
                }
                else {
                    setToastMessage("Your subscription unmapping is failed.");
                    setToastError(true);
                    setTimeout(() => {
                        setToastError(false);
                    }, 2000);
                }
            });
    }

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <Popup
                className="custom-modal custom-modal-integration-sm"
                open={unMapActionPopup}
                onClose={() => { setUnMapActionPopup(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Agreement Mapping</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container>
                            <div >
                                <span className='mt-4 mb-5 text-strong text-regular'>Are you sure you want to unmap the addition?</span>
                                <p className='mt-2'> <span >Unmapping a subscription will stop syncing the subscription changes to the connectwise instance</span></p>
                            </div>
                            <div className="mt-4 btn-wrapper text-center">
                                {!confirmLoadingFlag && <><Button
                                    className="ml-3 mr-3 btn-border-light w-md-auto col-xs-12"
                                    variant="light"
                                    onClick={() => {
                                        close();
                                    }}
                                >
                                    Cancel
                                </Button>
                                    <Button
                                        className="px-4 mx-2"
                                        onClick={() => {
                                            UnmapAction(completeRowData.agreementMappingId);
                                        }}
                                    >Unmap</Button></>}
                                {
                                    confirmLoadingFlag &&
                                    <>
                                        <Button
                                            variant="light"
                                            disabled
                                            className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                        >Cancel
                                        </Button>
                                        <Button
                                            disabled
                                            className="ml-3 btn-border-light w-md-auto col-xs-12"
                                            variant="light"
                                        >
                                            Processing . . .
                                        </Button>
                                    </>
                                }
                            </div>
                        </Container>
                    </div>)}
            </Popup>
        </>
    )
}

export default Unmap
