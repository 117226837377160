import React, { useState, useEffect, useRef } from 'react'
import { Container, Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import "./styles.scss";

const GWSFilterBox = ({setShowFilterBox, ...props}) => {
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedBillingType, setSelectedBillingType] = useState([]);
    const { setIsAutoRefreshEnabled } = props;
    const filterBoxRef = useRef(null);
    const [isFilterButtonClick, setIsFilterButtonClick] = useState(false);

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    const closeFilterBox = () => {
    // Only re-enable auto-refresh if no filters are active
    if (selectedSubscriptionType.length === 0 
        // && selectedStatuses.length === 0 
        && selectedBillingType.length === 0) {
      props.setIsAutoRefreshEnabled(true);
    }
    };

    // Function to update selected services
    const handleSubscriptionTypeChange = (subType) => {
        setSelectedSubscriptionType((prevSelectedSubscriptionType) => {
            if (prevSelectedSubscriptionType.includes(subType)) {
                return prevSelectedSubscriptionType.filter((code) => code !== subType);
            } else {
                return [...prevSelectedSubscriptionType, subType];
            }

        });
    };

    // Function to update selected statuses
    const handleStatusChange = (id) => {
        setSelectedStatuses((prevSelectedStatuses) => {
            if (prevSelectedStatuses.includes(id)) {
                return prevSelectedStatuses.filter((statusId) => statusId !== id);
            } else {
                return [...prevSelectedStatuses, id];
            }
        });
    };

    // Function to update selected account types
    const handleBillingTypeChange = (id) => {
        setSelectedBillingType((prevSelectedBillingType) => {
            if (prevSelectedBillingType.includes(id)) {
                return prevSelectedBillingType.filter((typeId) => typeId !== id);
            } else {
                return [...prevSelectedBillingType, id];
            }
        });
    };

    // Function to handle "All SubscriptionType" checkbox
    const handleAllSubscriptionTypeChange = (isChecked) => {
        setSelectedSubscriptionType(isChecked ? props.subscriptionTypeData.map((subType) => subType.id) : []);
    };

    // Function to handle "All Statuses" checkbox
    const handleAllStatusesChange = (isChecked) => {
        setSelectedStatuses(isChecked ? props.statusData.map((status) => status.id) : []);
    };

    // Function to handle "All Account Types" checkbox
    const handleAllBillingTypeChange = (isChecked) => {
        setSelectedBillingType(isChecked ? props.billingTypeData.map((account) => account.id) : []);
    };

    useEffect(() => {
        (props.subscriptionType != null || props.subscriptionType != undefined) ? setSelectedSubscriptionType(props.subscriptionType) : setSelectedSubscriptionType([]);
        (props.status != null || props.status != undefined) ? setSelectedStatuses(props.status) : setSelectedStatuses([]);
        (props.billingType != null || props.billingType != undefined) ? setSelectedBillingType(props.billingType) : setSelectedBillingType([]);

    }, []);

    useEffect(() => {
    const isFilterActive = selectedSubscriptionType.length > 0 
    // || selectedStatuses.length > 0 
    || selectedBillingType.length > 0;
    props.setIsFilterOrSearchActive(isFilterActive);
    }, [selectedSubscriptionType, selectedStatuses, selectedBillingType]);

    useEffect(() => {
    // Handler for clicking outside the filter box
    const handleClickOutside = (event) => {
        const filterButton = document.getElementById('filterblock');
        const filterButtonActive = document.querySelector('.filter-black-active');
        const isFilterClick = filterButton?.contains(event.target) || 
                            filterButtonActive?.contains(event.target);
        if (isFilterClick) {
            setIsFilterButtonClick(true);
            return;
        }
        if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
            setShowFilterBox(false);
        }
        
        setIsFilterButtonClick(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, []);

    return (
        <>
            <div className="filter-box" ref={filterBoxRef}>
                <Container>
                    <Col className="d-flex justify-content-between d-md-none align-items-center px-8 flex-grow-0">
                        <h2>Filters</h2>
                        <XLg size={16} onClick={() => {
                            // props.setShowFilterBox(false);
                            setShowFilterBox(false);
                             // Only re-enable auto-refresh if no filters are active
                                if (selectedSubscriptionType.length === 0 && selectedStatuses.length === 0 && selectedBillingType.length === 0) {
                                props.setIsAutoRefreshEnabled(true);
                                }
                        }} />
                    </Col>


                    <Row className="mb-3 mobile-filter-row">
                        <Col className="filter-column">
                            <h3 className="mb-3">Billing Term</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {props.billingTypeData && props.billingTypeData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`account-all`}
                                            label="All Account Types"
                                            checked={(selectedBillingType.length === props.billingTypeData.length)}
                                            onChange={(e) => handleAllBillingTypeChange(e.target.checked)}
                                        />}
                                        {props.billingTypeData && props.billingTypeData.length > 0 &&
                                            props.billingTypeData.map((account) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={account.id}
                                                        type="checkbox"
                                                        id={`account-${account.id}`}
                                                        label={account.name}
                                                        checked={selectedBillingType.includes(account.id)}
                                                        onChange={() => handleBillingTypeChange(account.id)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>
                        </Col>

                        <Col className="filter-column">
                            <h3 className="mb-3">Status</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {props.statusData && props.statusData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`status-all`}
                                            label="All Statuses"
                                            checked={(selectedStatuses.length === props.statusData.length)}
                                            onChange={(e) => handleAllStatusesChange(e.target.checked)}
                                        />}
                                        {props.statusData && props.statusData.length > 0 &&
                                            props.statusData.map((statusItem) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={statusItem.id}
                                                        type="checkbox"
                                                        id={`status-${statusItem.id}`}
                                                        label={statusItem.name}
                                                        checked={selectedStatuses.includes(statusItem.id)}
                                                        onChange={() => handleStatusChange(statusItem.id)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>

                        </Col>

                        <Col className="filter-column">
                            <h3 className="mb-3">Subscription Type</h3>
                            <div>
                                <div className="filter-options">
                                    <>
                                        {props.subscriptionTypeData && props.subscriptionTypeData.length > 0 && <Form.Check
                                            className="mb-2"
                                            type="checkbox"
                                            id={`subscriptionType-all`}
                                            label="All Subscription Types"
                                            checked={(selectedSubscriptionType.length === props.subscriptionTypeData.length)}
                                            onChange={(e) => handleAllSubscriptionTypeChange(e.target.checked)}
                                        />}
                                        {props.subscriptionTypeData && props.subscriptionTypeData.length > 0 &&
                                            props.subscriptionTypeData.map((subType) => {
                                                return (
                                                    <Form.Check
                                                        className="mb-2"
                                                        key={subType.id}
                                                        type="checkbox"
                                                        id={`subscriptionType-${subType.id}`}
                                                        label={subType.name}
                                                        checked={selectedSubscriptionType.includes(subType.id)}
                                                        onChange={() => handleSubscriptionTypeChange(subType.id)}
                                                    />
                                                );
                                            })}
                                    </>
                                </div>
                            </div>

                        </Col>
                    </Row>

                    <Row className="actions-row">
                        <Col className="d-flex justify-content-end">
                            {((selectedSubscriptionType.length > 0 || selectedStatuses.length > 0 || selectedBillingType.length > 0) || (((props.subscriptionType && props.subscriptionType.length > 0) || (props.status && props.status.length > 0) || (props.billingType && props.billingType.length) > 0)))
                                ? <Button
                                    className="btn btn-lg mx-3 btn-border-light"
                                    variant="light"
                                    onClick={() => {
                                        props.setScrollToDivTop(true);
                                        props.setEmptyUserFilters(false);
                                        setSelectedSubscriptionType([]);
                                        setSelectedStatuses([]);
                                        setSelectedBillingType([]);
                                        props.clearExistingData();
                                        props.setPageNo(1);
                                        props.setPagesWithPending(new Set());
                                        props.setLoadedPages(new Set([1]));
                                        props.GetTableData(1, props.pageSize, props.searchValueTable, [], [], [], props.sortCol, props.sortDir);
                                        props.setFilterFlag(false);
                                        props.setSubscriptionType();
                                        props.setBillingType();
                                        props.setStatus();
                                        closeFilterBox();
                                    }}
                                >
                                    Clear
                                </Button> :
                                <Button
                                    className="btn btn-lg"
                                    variant="light"
                                    disabled
                                >
                                    Clear
                                </Button>}
                            {(props.status || props.billingType || props.subscriptionType) ||
                                (
                                    (selectedSubscriptionType.length > 0 || selectedStatuses.length > 0 || selectedBillingType.length > 0)
                                    &&
                                    (selectedSubscriptionType.length !== props.subscriptionTypeData.length ||
                                        selectedStatuses.length !== props.statusData.length ||
                                        selectedBillingType.length !== props.billingTypeData.length)) ? (
                                <Button
                                    className="btn btn-lg"
                                    variant="primary"
                                    onClick={() => {
                                        props.setScrollToDivTop(true);
                                        props.setSubscriptionType(selectedSubscriptionType);
                                        props.setBillingType(selectedBillingType);
                                        props.setStatus(selectedStatuses);
                                        setShowFilterBox(false);
                                        props.clearExistingData();
                                        props.setPageNo(1);
                                        props.setPagesWithPending(new Set());
                                        props.setLoadedPages(new Set([1]));
                                        props.GetTableData(1, props.pageSize, props.searchValueTable, selectedStatuses, selectedSubscriptionType, selectedBillingType, props.sortCol, props.sortDir,false,true);
                                        props.setFilterFlag(true);
                                        props.setFilterApplied(true);
                                    }}
                                >
                                    Apply
                                </Button>
                            ) : (

                                <Button
                                    className="btn btn-lg ml-3"
                                    variant="light"
                                    disabled
                                >
                                    Apply
                                </Button>
                            )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default GWSFilterBox