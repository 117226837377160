import React, { useState } from 'react';
import { Button, Col, Container, Form, Nav, Row, Tab, TabContent } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import { emailRegEx } from '../../../../../GlobalComponents/RegExPatterns';
import styles from './configureNotification.module.scss';

const ConfigureNotificationsTab = () => {
  const [invoiceNotificationEmail, setInvoiceNotificationEmail] = useState('');
  const [invoiceNotificationEmailList, setInvoiceNotificationEmailList] = useState([]);
  const [errorStateInvoiceNotification, setErrorStateInvoiceNotification] = useState(false);
  const [errorStateMessage, setErrorStateMessage] = useState('');
  const [tabName, setTabName] = useState("View Template");

  const EmailSubmitInvoiceNotification = (e) => {
    const emailList = invoiceNotificationEmail.split(';').filter(email => email.trim() !== '');
    e.preventDefault();

    let isValid = true;
    emailList.forEach(email => {
      if (!emailRegEx.test(email.trim())) {
        isValid = false;
        setErrorStateInvoiceNotification(true);
      }
    });

    if (isValid && invoiceNotificationEmailList.length < 5) {
      if (invoiceNotificationEmailList.some(email => emailList.includes(email))) {
        setErrorStateInvoiceNotification(true);
        setErrorStateMessage("Duplicate email is not allowed");
      } else {
        setInvoiceNotificationEmailList([...invoiceNotificationEmailList, ...emailList]);
        setInvoiceNotificationEmail('');
        setErrorStateInvoiceNotification(false);
      }
    } else {
      setErrorStateInvoiceNotification(true);
      setErrorStateMessage("Please enter a valid email");
    }
  };

  const emailTemplate = `
    <table style="background-color: rgba(245, 246, 255, 1); padding: 15px; margin-left: auto; margin-right: auto; font-size: 0.875rem; font-weight: 500">
      <tbody>
        <tr>
          <td>
            <center><img src="https://app-cloudmarketplace-cms-qa.azurewebsites.net/media/Email%20Template%20Images/email-logo.svg" alt="" style="width: 9.063rem; height: auto; display: block"></center>
            <br><br>
          </td>
        </tr>
        <tr>
          <td>
            <center><img src="https://app-cloudmarketplace-cms-qa.azurewebsites.net/media/Email%20Template%20Images/invite-customer-bg.svg" alt="" style="width: 8.625rem; height: auto; display: block"></center>
            <br><br>
          </td>
        </tr>
        <tr>
          <td>
            <br><br>
            Hi <span>[UserName] ,<br><br></span>
            <span>Your password has been reset. Below are the details.<br><br></span>
            <div style="background-color: rgba(255, 255, 255, 1); padding: 10px; border-radius: 10px">
              Username: [UserName]<br>
              Initial Password: [Password]<br>
              <span><br><a href="cloud-marketplace-qa.synnexb2b.com.au" title="cloud-marketplace-qa.synnexb2b.com.au" target="_self">Click here to login</a></span>
            </div><br>
            <p style="color: rgba(128, 128, 128, 1)">Please do not respond to this email, it is an automatically generated response. If you have any questions, please contact your Synnex Account Manager.</p>
            From, <br>Synnex Customer Support
          </td>
        </tr>
      </tbody>
    </table>
  `;

  return (
    <div className={styles.configureNotification}>
      <div className="details-box">
      <Container fluid>
        <div className={styles.header}>
          {/* <h1>Configure Notification</h1> */}
           <div className="header p-4 py-3">
           <h2 className="mb-0 px-3">Configure Notification</h2>     
           </div>
          <Tab.Container id="main-tab-bar" activeKey={tabName}>
          <Row>
              <Col md={12}>
                <Nav variant="pills" className="mb-1">
                  <Nav.Item>
                    <Nav.Link 
                      eventKey="View Template" 
                      className="dashboard-tabs pl-6 pr-6 pt-3 pb-3"
                      onClick={() => setTabName("View Template")}
                    >
                      View Template
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link 
                      eventKey="Download Report" 
                      className="dashboard-tabs pl-6 pr-6 pt-3 pb-3"
                      onClick={() => setTabName("Download Report")}
                    >
                      Download Report
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
          </Row>

{/* <Tab.Content>
                <Tab.Pane eventKey="View Template">
                  <div 
                    className={styles.templateContainer}
                    dangerouslySetInnerHTML={{ __html: emailTemplate }}
                  />
                </Tab.Pane>
</Tab.Content> */}




          </Tab.Container>
        </div>

        <div className={styles.content}>
          <Form>
            <div className={styles.notificationSettings}>
              <span>Send notification email on</span>
              <Form.Select className={styles.monthDay}>
                <option value="1st">1st</option>
                <option value="15th">15th</option>
              </Form.Select>
              <span>of each month showing</span>
              <Form.Select className={styles.days}>
                <option value="30">30 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
              </Form.Select>
              <span>renewal date</span>
            </div>

            <div className={styles.reportNote}>
              Report will be sent to the reseller in an CSV format with customer subscription renewal details.
            </div>

            <div className="send-notifications-tab__resellers-email">
          <div className='px-2'>
            <Row className='px-3 align-items-center'>
              <h3>Reseller Email Id</h3>
              <Col md={4}>
                <Form.Group controlId="invoiceNotificationEmail">
                  <Form.Label className='justify-content-between d-flex col-12'>
                    <span>Add Email</span>
                    <span className={`float-right ${styles['maximum-add']} ${invoiceNotificationEmailList.length >= 5 ? "text-danger" : ""}`}>Max 5</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={invoiceNotificationEmail}
                    maxLength="250"
                    className={errorStateInvoiceNotification ? "border-danger" : ""}
                    name="invoiceNotificationEmail"
                    onChange={(e) => {
                      setErrorStateInvoiceNotification(false);
                      setErrorStateMessage("");
                      let count = e.target.value.split(';').length;
                      if (invoiceNotificationEmailList.length + count <= 5) {
                        setInvoiceNotificationEmail(e.target.value);
                      }
                    }}
                    onKeyDown={(e) => {
                      let count = e.target.value.split(';').length;
                      if (e.key === 'Enter' && invoiceNotificationEmailList.length < 5 && invoiceNotificationEmailList.length + count <= 5) {
                        EmailSubmitInvoiceNotification(e);
                      }
                    }}
                  />
                </Form.Group>
                {!errorStateInvoiceNotification && invoiceNotificationEmail.length > 0 && <span className='text-medium text-muted mb-2'>Use Semicolon to add multiple mail ids. Hit Enter to add your email id(s).</span>}
                {errorStateInvoiceNotification ? <span className='text-medium text-danger mb-2'>{errorStateMessage}</span> :
                  <p className='empty-paragragh mb-6'></p>}
              </Col>
              <Col md={8}>
                <div className='mt-1 mb-0 d-flex flex-wrap'>
                  {invoiceNotificationEmailList.map((email, index) => (
                    <div key={index} className={`mr-2 mb-0 px-1 col-lg-3 col-md-4 col-xs-5 ${styles['member-options']}`}>
                      <label className='ml-0'>
                        <span>{email}</span>
                        <XLg
                          size={16}
                          className="cpointer text-strong close-icon-popup-sm"
                          onClick={() => {
                            const updatedEmailList = [...invoiceNotificationEmailList];
                            updatedEmailList.splice(index, 1);
                            setInvoiceNotificationEmailList(updatedEmailList);
                          }}
                          color="#6A6A6A"
                        />
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>

            <div className={styles.note}>
              Note: Renewal price is based on current pricing, please check back closer to renewal date.
            </div>

            <div className={styles.actions}>
              <Button variant="light" className={styles.cancelBtn}>
                Cancel Notification
              </Button>
              <Button variant="primary" className={styles.proceedBtn}>
                Proceed
              </Button>
            </div>
          </Form>
        </div>
      </Container>
      </div>
    </div>
  );
};

export default ConfigureNotificationsTab;