import React , {useState,useEffect} from 'react'
import { LANDING_PAGE_CMS_DATA } from '../../../Utils/GlobalConstants';
import { cmsApi } from '../../../Utils/API';

const MaintainancePage = ({setMaintainancePageActive}) => {

    const [maintainancePageData, setMaintainancePageData] = useState([]);           // Storing cookie policy html coming from CMS

    // Function for calling Cookie Policy item data from Orchid Core CMS
    async function GetMaintainancePageData() {
        const data = `{maintenancePage {
                content{
                html
                }
                maintenanceFlag
            }
        }`;
    
        // Defining Header Configuration
        const config = {
          headers: {
            "Content-Type": "application/graphql",
            Accept: "application/graphql",
          },
        };
    
        // Axios Post Request
        await cmsApi
          .post(LANDING_PAGE_CMS_DATA, data, config)
          .then((resp) => {
            // handle success
            // console.log(resp.data.data.cookiePolicy[0].content.html);
            setMaintainancePageActive(resp.data.data.maintenancePage[0].maintenanceFlag);
            setMaintainancePageData(
                resp.data.data.maintenancePage[0].content.html
            );
          })
          .catch((error) => {
            // handle error
            // console.log(error);
          });
      }

    // UseEffect Defination to call the get Cookie Policy items function from CMS
    useEffect(() => {
        GetMaintainancePageData();
      }, []);

    //   useEffect(() => {
    //     GetMaintainancePageData();
    //   }, [window.location]);

  return (
    <div className='mb-0' dangerouslySetInnerHTML={{__html:maintainancePageData}}>
    </div>
  )
}

export default MaintainancePage